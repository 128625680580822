<div
  [ngClass]="
    type === 'warning'
      ? 'bg-orange-100 text-orange-900 hover:bg-orange-400 hover:text-yellow-200'
      : type === 'error'
      ? 'bg-red-100 text-red-900 font-semibold animate-pulse hover:bg-red-500 hover:text-yellow-200 hover:animate-none'
      : type === 'success'
      ? 'bg-green-200 text-blue-950'
      : 'hover:bg-gray-400 hover:text-yellow-200'
  "
  class="flex justify-center text-wrap items-center gap-2 text-sm font-light px-1 py-0.5 border rounded-xl hover:cursor-pointer"
>
  <span [ngClass]="icon"></span>{{ message }}
</div>
