import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'tip',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tip.component.html',
  styleUrl: './tip.component.css'
})
export class TipComponent implements OnInit, OnChanges {
  @Input() message: string = 'Alguma dica';
  @Input() icon: string = 'icon-[mdi--information-variant-circle-outline] text-2xl';
  @Input({ required: false }) type: 'normal'|'success'|'warning'|'error' = 'normal';

  ngOnInit(): void {

  }
  ngOnChanges(changes: SimpleChanges): void {

    if(changes && changes['message']) { }
    if(changes && changes['icon']) { }

  }

}
